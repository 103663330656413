import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import NextLink from '../components/NextLink';
import PerfectScrollbar from 'react-perfect-scrollbar';

import style from '../assets/style/pages/about.module.scss';

export default ({ data }) => {

  const image = data.aboutImage.childImageSharp.fluid;

  console.log(style)

  return (
    <Layout>
      <SEO title="O nas" />

      <section className={style.aboutSection}>
        <div className="container">
          <div className={`row ${style.aboutSection__row}`}>

            <div className={`col-12 col-lg-6 col-xl-5 offset-xxl-1 ${style.imageColumn}`}>
              <Img fluid={image} alt=""/>
            </div>

            <div className="col-12 col-lg-6 offset-xl-1 col-xxl-5">
              <h1>Kim jesteśmy</h1>

              <PerfectScrollbar className={style.scrollArea}>
                <p>
                  BPD Rafał Matusik z siedzibą w Krakowie to firma działająca od. 1998 r., składająca się z wysoko wykwalifikowanych inżynierów. Specjaliści ci mają duże doświadczenie w świadczeniu usług w zakresie dróg, ulic i wewnętrznych układów. Pracownia specjalizuje się projektowaniu ścieżek rowerowych i jest liderem w ich opracowaniu.
                  <br /><br />
                  Obsługujemy szeroką gamę klientów, w tym Gminę Miejską Kraków oraz gminy i starostwa w Małopolsce, deweloperów, biura architektoniczne, firmy zarządzające budową i wykonawców. Naszą firmę tworzy zgrany i dobrze zorganizowany zespół, którego celem jest profesjonalne, szybkie zrealizowanie wyznaczonego zadania. Naszym priorytetem jest traktowanie każdego klienta indywidualnie z pełnym wsparciem w procesie inwestycyjnym przez cały okres realizacji zadania.
                  <br /><br />
                  W pracy kierujemy się uczciwością i rzetelnością. Satysfakcja naszych klientów jest miernikiem naszego sukcesu. Nasi inżynierowie posiadają niezbędne uprawnienia zgodnie z wymogami polskiego prawa.
                </p>
              </PerfectScrollbar>

              <NextLink to="/wspolpraca/">Dalej</NextLink>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    aboutImage: file(relativePath: { eq: "assets/images/grid.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
